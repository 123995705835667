import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

import moment from 'moment'

import styled from 'styled-components'

import Slider from 'react-slick'
import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'

import ArrowButton from './buttons/ArrowButton'
import RepertoireDay from './RepertoireDay'

/* * Component for week repertoire view 
   
   Calendar which generates RepertoireDay(s) from todayDate to the last defined in Sanity.
   (If the first defined day is later than todayDate it works also properly)
   
   Using react-slick

   It receives todayDate in props which is (moment) current day calculated in the parent (repertoireList)
   Although it typically is today, it can be "fooled" by setting testDay query param
   like so: ?testDay=2020-12-01 (for testing purposes)

   If there is no defined day in calendar - it is not rendered.

*/

const RepertoireCalendar = ({ todayDate }) => {
  // settings for react slick
  // breakpoints are "mobile last" - hence breakpoint describes
  // ~ if screen narrower use this options
  // breakpoints are picked by trial and error
  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    // slidesToScroll: 2,
    swipeToSlide: true,
    prevArrow: <ArrowButton previous />,
    nextArrow: <ArrowButton next />,
    accessibility: true,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 5,
          // slidesToScroll: 5,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 4,
          // slidesToScroll: 5,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          // slidesToScroll: 4,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 812,
        settings: {
          slidesToShow: 2,
          // slidesToScroll: 3,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          // slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
    ],
  }

  const repertoireCalendar = useStaticQuery(getRepertoireCalendar)
  const repertoireSchedule = repertoireCalendar.allSanityProjectionsDay.nodes

  // FILL CALENDAR (we get only days defined in Sanity)

  // get first and last day
  const firstDayDate = moment(repertoireSchedule[0].day)
  // added 1 day to lastDayDate to not omit last day defined in sanity
  const lastDayDate = moment(
    repertoireSchedule[repertoireSchedule.length - 1].day
  ).add(1, 'days')

  // generate consecutive days in calendar
  const calendarConsecutiveDays = []

  // don't show past days
  const currentDay = firstDayDate > todayDate ? firstDayDate : todayDate
  while (currentDay <= lastDayDate) {
    const found = repertoireSchedule.find(
      item => item.day === currentDay.format('YYYY-MM-DD')
    )

    calendarConsecutiveDays.push({
      day: currentDay.format('YYYY-MM-DD'),
      projections: found ? found.projections : undefined,
    })
    currentDay.add(1, 'days')
  }

  return (
    <StyledCalendar className="container-xxl">
      {/* if there are any defined calendar days - show slider with calendar */}
      {calendarConsecutiveDays.length > 0 && (
        <>
          <h2 className="visually-hidden">Kalendarz</h2>
          <StyledSlider {...settings}>
            {calendarConsecutiveDays.map(({ day, projections }) => (
              <RepertoireDay key={day} repertoire={{ day, projections }} />
            ))}
          </StyledSlider>
        </>
      )}

      {/* if there are no future projections - hence no days in calendar */}
      {calendarConsecutiveDays.length === 0 && (
        <div className="emptyMoviesList">
          <span>Brak zaplanowanych projekcji w najbliższym czasie. </span>
        </div>
      )}
    </StyledCalendar>
  )
}

RepertoireCalendar.propTypes = {
  todayDate: PropTypes.instanceOf(moment),
}

export default RepertoireCalendar

const StyledSlider = styled(Slider)`
  /* add margins for previous and next buttons */
  .slick-list {
    margin: 0 2.5rem;
  }

  /* force carousel elements to have same height 
    https://stackoverflow.com/questions/49028877/slick-carousel-force-slides-to-have-the-same-height
    with my amendments for react-slick
  */
  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: inherit;
    margin-bottom: 1rem;
  }
  .slick-slide > div {
    height: 100%;
  }
  .slick-slide > div > div {
    height: 100%;
  }
`

const StyledCalendar = styled.div`
  /* fast and dirty styling to match left & right side of element with "no movies below" */
  .emptyMoviesList span {
    margin: 0 -1rem;
  }
`

const getRepertoireCalendar = graphql`
  query {
    allSanityProjectionsDay(filter: {}, sort: { fields: day }) {
      nodes {
        id
        day
        projections {
          time
          film {
            titlePl
            slug {
              current
            }
          }
          _key
          languageVersion {
            name
          }
        }
      }
    }
  }
`

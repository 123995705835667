import React from 'react'

const PreviousArrowSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.75 55.75">
    <path
      className="color"
      fill="black"
      d="M12.74 23.91c.29-.28.6-.52.92-.72l21.6-21.6a5.4 5.4 0 017.64 7.63l-18.6 18.6L43 46.54a5.4 5.4 0 01-7.64 7.64l-21.7-21.7a5.37 5.37 0 01-2.49-4.64 5.37 5.37 0 011.57-3.91z"
    />
  </svg>
)

export default PreviousArrowSvg

import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import RepertoireList from '../components/repertoireList/repertoireList'

const IndexPage = () => (
  <Layout>
    <SEO title="Repertuar" />
    <RepertoireList />
  </Layout>
)
export default IndexPage

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import moment from 'moment'

import withLocation from '../withLocation'

import RepertorieListMovieCard from './repertorieListMovieCard'
import RepertoireCalendar from '../RepertoireCalendar/RepertoireCalendar'
import TestDayInfo from './testDayInfo'
import {
  filterRepertoireList,
  getTodayDate,
} from '../../utils/filterRepertoire'

/* 
  * Component which generates the repertoire lists
  
  Movies are sorted by priorityOnList defined in movie. 
  priorityOnList is number - the highest - higher the movie should be

  The component shows only days which onScreen.endDate is after 'today'

  If there is no movie to show - it renders info "There are no coming soon movies"

  It receives testDay in props which is moment (or false) date.
  If testDay is valid moment date it 'fools' the component that this date is 'today'

  When testing, we can also find in the browser console warnign showing an object 'Past movies omitted'
  with movies omitted due to its endDate

  testDay is received from withLocation wrapper (it comes from query param)
*/

const RepertoireList = ({ testDay }) => {
  const { allSanityFilm } = useStaticQuery(getMovies)
  const movies = allSanityFilm.nodes

  // filters out 'outdated' movies
  const moviesStillOnScreen = filterRepertoireList({ testDay, movies })

  return (
    <StyledRepertoireList className="container-xxl">
      {testDay && <TestDayInfo testDayLabel={testDay.format('YYYY-MM-DD')} />}
      <h1 className="container-xxl">Repertuar</h1>
      <RepertoireCalendar todayDate={getTodayDate(testDay)} />

      <h2 className="visually-hidden">Filmy</h2>

      {/* if there are movies to show - render them */}
      {moviesStillOnScreen.length > 0 &&
        moviesStillOnScreen.map(movie => (
          <RepertorieListMovieCard movie={movie} key={movie.slug.current} />
        ))}

      {moviesStillOnScreen.length === 0 && (
        <div className="emptyMoviesList">
          <span>Brak zaplanowanych filmów w najbliższym czasie. </span>
        </div>
      )}
    </StyledRepertoireList>
  )
}

RepertoireList.propTypes = {
  testDay: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(moment)]),
}

export default withLocation(RepertoireList)

const StyledRepertoireList = styled.div`
  .emptyMoviesList {
    color: var(--grazynaRed);
    text-align: center;

    span {
      border: 1px solid var(--grayLight);
      font-size: 1.5rem;
      padding: 1.5rem;
      margin-bottom: 1rem;
      display: block;
    }
  }
`

const getMovies = graphql`
  query {
    allSanityFilm(sort: { fields: priorityOnList, order: DESC }) {
      nodes {
        titlePl
        titleOriginal
        genres {
          name
          id
        }
        year(formatString: "YYYY")
        direction
        countries {
          name
          id
        }
        cast
        posterImage {
          asset {
            fluid(maxWidth: 600) {
              ...GatsbySanityImageFluid
            }
          }
        }
        backgroundImage {
          asset {
            fluid(maxWidth: 1600) {
              ...GatsbySanityImageFluid
            }
          }
        }
        slug {
          current
        }
        onScreen {
          endDate(formatString: "DD.MM.YYYY")
          startDate(formatString: "DD.MM.YYYY")
          machineEndDate: endDate(formatString: "YYYY-MM-DD")
        }
        duration: time
        ageRating {
          id
          name
        }
        languageVersions {
          id
          name
        }
      }
    }
  }
`

import React from 'react'

const NextArrowSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.75 55.75">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.75 55.75">
      <path
        className="color"
        fill="black"
        d="M43 23.92a5.36 5.36 0 00-.9-.73L20.47 1.59a5.4 5.4 0 00-7.63 7.63l18.6 18.6-18.7 18.71a5.4 5.4 0 107.64 7.64l21.7-21.7a5.35 5.35 0 002.49-4.64 5.36 5.36 0 00-1.57-3.91z"
      />
    </svg>
  </svg>
)

export default NextArrowSvg

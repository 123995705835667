import React from 'react'
import styled from 'styled-components'
import PreviousArrowSvg from './PreviousArrowSvg'
import NextArrowSvg from './NextArrowSvg'

/* 
    * ARROW BUTTON component for react-slick

    renders arrow button for slick gallery (previous & next)
    to use it we have to pass 'previous' or 'next' (otherwise won't show and console warn)

    like so:

    <ArrowButton previous />,
    <ArrowButton next />,

    For now, these buttons are used for react-slick carousel
    * Hence there is no text on button and it has aria-hidden="true"
    (nor non-visual users the carousels doesn't hide all the content)

    it recreates also browser's default outline (when button is focused)
    (in Firefox is not really the same like default, but still highlights focused button)

    * Buttons colors are defined in (global) css properties

*/

const ArrowButton = props => {
  const { previous, next, className, onClick } = props

  const svgImageComponent =
    (previous && <PreviousArrowSvg />) || (next && <NextArrowSvg />)

  if (!svgImageComponent) {
    console.warn('Not passed previous nor next to the ArrowButton component')
    return false
  }

  return (
    <StyledArrowButton
      aria-hidden="true"
      className={className}
      onClick={onClick}
    >
      {svgImageComponent}
    </StyledArrowButton>
  )
}

const StyledArrowButton = styled.button`
  position: absolute;
  top: 50%;
  z-index: 10;
  font-size: 1rem;
  width: 4rem;
  height: 4rem;
  padding: 0.5rem;

  /* default button color */
  svg path.color {
    fill: var(--carouselBtnDefaultColor);
    /* transition has to be here to have impact on button's image color */
    transition: var(--buttonTransition);
  }

  /* hover & focus colors */
  &:hover svg path.color,
  &:focus svg path.color {
    fill: var(--carouselBtnFocusHoverColor);
  }

  &:focus {
    /* use browser default (focus) outline */
    /* technique: https://css-tricks.com/copy-the-browsers-native-focus-styles/ */
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }

  /* style when button is disabled (e.g. there are no more items) */
  &.slick-disabled svg path.color {
    fill: var(--carouselBtnDisabledColor);
  }

  &:before {
    /* got rid of content for before to prevent displaying default react-slick arrow */
    content: '';
  }
`

export default ArrowButton

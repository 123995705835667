import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import moment from 'moment'

import paths from '../../constants/paths'
import { shadowPrimary } from '../../styles-global/styled-components/styled-mixins'

const RepertoireDay = ({ repertoire: { day, projections = [] } }) => {
  // hasMounted used experimentally to avoid problems with "wrong" marking inactive days in calendar
  // more here: https://www.notion.so/jurekskowron/Gatsby-dynamiczne-elementy-rehydration-43bfbcda171b4d13ab4c1638093169f2

  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  // if there are projections for the day
  if (projections && projections.length > 0) {
    // sort projections by the time (it's string but with leading 0)
    projections.sort((a, b) =>
      a.time === b.time ? 0 : a.time > b.time ? 1 : -1
    )

    const pathToIndividualMovie = paths.repertoireMovies.path

    return (
      <Day>
        <h3 className="dayLabel">
          <time dateTime={moment(day).format('YYYY-MM-DD')}>
            <span className="visually-hidden">
              {moment(day).format('D MMMM YYYY - dddd')}
            </span>
            <span className="dayOfWeek" aria-hidden="true">
              {moment(day).format('dd')}
            </span>
            <span className="dayOfMonth" aria-hidden="true">
              {moment(day).format('D')}
            </span>
            <span className="monthYear" aria-hidden="true">
              {moment(day).format('MMM YYYY')}
            </span>
          </time>
        </h3>
        <ul className="dayProjections">
          {projections.map(projection => (
            <li className="projection" key={projection._key}>
              {/* Temporary solution tab index is -1 to not force user to tab every projection - needs better solution */}
              <Link
                className="projectionLink"
                to={`${pathToIndividualMovie}/${projection.film.slug.current}`}
                tabIndex="-1"
              >
                {/* put date and time of the projection in <time> element */}
                <time
                  className="projectionTime"
                  dateTime={`${moment(day).format('YYYY-MM-DD')}T${
                    projection.time
                  }`}
                >
                  {projection.time}
                </time>{' '}
                <div className="projectionInfo">
                  <span className="projectionTitle">
                    {projection.film.titlePl}
                  </span>
                  <span className="projectionLang">
                    {projection.languageVersion?.name || 'nie zdefiniowano'}
                  </span>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </Day>
    )
  }

  // if there are no projections for the day

  {
    /* experiment - hasMounted gets 'true' value in browser because it is set in React.useEffect()
    hece we don't mark any days inactive when rendering DOM in server, only in client's browser
    and we can avoid rehydration problem
   */
  }
  let markInactiveDay = false
  if (hasMounted) {
    markInactiveDay = true
  }

  return (
    <Day inactive={markInactiveDay}>
      <h3 className="dayLabel">
        <time dateTime={moment(day).format('YYYY-MM-DD')}>
          <span className="visually-hidden">
            {moment(day).format('D MMMM YYYY - dddd')}
          </span>
          <span className="dayOfWeek" aria-hidden="true">
            {moment(day).format('dd')}
          </span>
          <span className="dayOfMonth" aria-hidden="true">
            {moment(day).format('D')}
          </span>
          <span className="monthYear" aria-hidden="true">
            {moment(day).format('MMM YYYY')}
          </span>
        </time>
      </h3>
      <div className="noProjections">
        <p>Kino nieczynne</p>
      </div>
    </Day>
  )
}

export default RepertoireDay

const Day = styled.div`
  ${shadowPrimary}

  display: flex;
  flex-direction: column;

  margin: 0 0.5rem;

  /* inactive day */
  ${({ inactive }) => inactive && `opacity: 0.5;`}

  .dayLabel {
    flex: 0 0;

    text-align: center;
    background-color: var(--dayLabelBgColor);
    color: var(--dayLabelColor);
    margin: 0;
    padding: 1rem;
  }

  .dayOfWeek {
  }

  .dayOfMonth {
    display: block;
    font-size: 4rem;
  }

  .monthYear {
    display: block;
    font-size: 1.2rem;
  }

  .dayProjections,
  .noProjections {
    flex: 1 0;
    list-style: none;
    padding: 0;

    display: flex;
    flex-direction: column;
    margin: 0.5rem;

    font-size: 1.2rem;
  }

  .projectionLink {
    --linkColor: var(--dayProjectionMainColor);

    text-align: left;

    display: flex;
    flex-direction: row;

    transition: var(--buttonTransition);
    border-bottom: 2px solid transparent;

    &:hover {
      text-decoration: none;
      border-color: inherit;
    }

    padding: 0.5rem;

    .projectionTitle {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .projectionInfo {
      flex: 1 1;
      overflow: hidden;
      margin-left: 0.5em;
    }

    .projectionLang {
      display: block;
      color: var(--dayProjectionSecondColor);
      font-size: 1rem;
    }
  }

  .noProjections {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      margin: 0;
      font-size: inherit;
      line-height: inherit;
    }
  }
`

import React from 'react'

import paths from '../../constants/paths'
import MovieInfo from '../movieInfo'
import MultipleBackground from '../multiBackground'
import MoviePosterImage from '../moviePosterImage'

import styles from './repertorieListMovieCard.module.scss'

/* 
TODO:
- semantyka (poprawny alt dla obrazka, accessible opisy przycisków (jak u commandeera - https://blog.comandeer.pl/ ))
*/

const RepertorieListMovieCard = ({
  movie: {
    titlePl,
    genres,
    countries,
    year,
    posterImage,
    backgroundImage,
    slug: { current: slug },
    onScreen,
    duration,
    ageRating,
    languageVersions,
  },
}) => {
  // try to get image assets - if failed - will store (and then pass) undefined
  const backgroundImageFluid = backgroundImage?.asset?.fluid
  const posterImageFluid = posterImage?.asset?.fluid

  const pathToIndividualMovie = paths.repertoireMovies.path

  return (
    <article className={`${styles.movieCard} element-dark`}>
      <div className={styles.movieInfoWrapper}>
        {/* MovieInfo is the component for bar displaying movie info (without background under it) */}
        <MovieInfo
          type="repertoireLinkElement"
          info={{
            titlePl,
            genres,
            onScreen,
            duration,
            ageRating,
            languageVersions,
            linkToMoviePage: `${pathToIndividualMovie}/${slug}`,
          }}
        />
      </div>

      <MoviePosterImage
        img={posterImageFluid} // if no image given - passes undefined - handled by the component
        className={styles.posterImage}
      />

      <MultipleBackground
        img={backgroundImageFluid} // if no image given - passes undefined
        className={styles.backgroundImage}
      />
    </article>
  )
}

export default RepertorieListMovieCard
